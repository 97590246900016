import LocomotiveScroll from 'locomotive-scroll';
import './css/locomotive-scroll.css'
import { gsap } from "gsap";



const scroll = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true,
    mobile: {
        breakpoint:0,
        smooth: false,
        multiplier: 15,
    },
    tablet: {
        breakpoint:0,
        smooth: false,
        multiplier: 1,
    }
});

setTimeout(() => {
    scroll.update();
}, 5000);

scroll.on('scroll', (args) => {
    // Get all current elements : args.currentElements
    if(typeof args.currentElements['hey'] === 'object') {
        let progress = args.currentElements['hey'].progress;

        var scrollTop = $(window).scrollTop();
        var main_sec_one = $('.main_sec_one');
        var main_sec_two = $('.main_sec_two');
        var main_sec_three = $('.main_sec_three');
        var main_sec_studio = $('.main_sec_studio');
        var latest_project = $('.latest_project');
      

        var windowHeight = $(window).height();
        var sec_one = $('.sec_one');
        var sec_two = $('.sec_two');
        var sec_three = $('.sec_three');
        var sec_studio = $('.sec_studio');
        var nav = $('.navigation');
        var label = $('.label')
        var label_h1 = $('.label h1')
        var studio_label = $('.studio_label')
        var studio_label_h1 = $('.studio_label h1')
        var label_activation = $('.label_activation')
        var label_activation_h1 = $('.label_activation h1')
        var studio_button_trans1 = $('.studio_label .button_trans1')
        var studio_button_trans2 = $('.studio_label .button_trans2')
        var wip_button_trans1 = $('.label .button_trans1')
        var wip_button_trans2 = $('.label .button_trans2')
        var activation_button_trans1 = $('.label_activation .button_trans1')
        var activation_button_trans2 = $('.label_activation .button_trans2')
        
        var label_button = $('.label button')
        var studio_label_button = $('.studio_label button')
        var label_activation_button = $('.label_activation button')

        //console.log(progress)
        //Banner
        if(progress > main_sec_one.offset().top){
            var fillScreen = (Math.max(95,Math.min(101 - Math.round(progress*10),100)))
            var clamp =  Math.max(0,Math.min((progress-.2)*450,500))

            if(fillScreen < 99){
                sec_one.add(latest_project).css({
                    //width: 95 + '%',
                    //height:fillScreen + 'vh',
                    //'border-radius':100+ 'px',
                    transition:'width .2s ease-in-out',
                    
                })
            }else{
                sec_one.add(latest_project).css({
                    //width: 100 + '%',
                    //height:fillScreen + 'vh',
                    //'border-radius':0+ 'px',
                    //transition:'width .2s ease-in-out',
                    
                })
            }
           // console.log(/*Math.floor(progress*10)*/ fillScreen)
            

            
        }
      
        //WiP Trigger
        if(progress > main_sec_two.offset().top){
            var figure = 120;
             var squareCorners =(((progress) - (main_sec_two.offset().top)))
            var clamp = (figure - Math.max(0,Math.min(squareCorners,figure)))
            var fillScreen = Math.max(95,Math.min((squareCorners+140)/2,100))
          

            sec_two.css({
                width:100 + '%',
                height:100 + 'vh',
                //transition:' width .5s ease-out,border-radius .3s',
                //'border-radius':0 + 'px',
                
               
                
            })
            nav.css({
                color:"black",
                'text-shadow':'0px 0px 0px'
            })

            /*$('.sec_two span video').css({
                transform:'scale(1.2)',
                transition:'transform .5s'
            })*/

            label.css({
                "padding":"30px 100px",
                opacity:1
            })

            label_h1.css({
                "visibility":"visible",
                "opacity":"1",
                "width":"fit-content",
                'letter-spacing':1
            })

            $('.sec_two .overlay').css({
                'opacity':1
            })

            wip_button_trans1.css({
                "transform":" translateY(-100px)"
            })

            wip_button_trans2.css({
                "transform":" translateY(0px)"
            })

            label_h1.css({
                'color':"black"
            })

            

        }else{
            sec_two.css({
                width:100  + '%',
                //height:fillScreen + 'vh',
                //transition:' width .5s ease-out,border-radius .3s',
                //'border-radius':100+'px',
               
                
            })
            nav.css({
                color:'white',
                'text-shadow':'0px 0px 2px black'
            })

            label.css({
                "padding":"30px 100px",
                opacity:0
            })

            label_h1.css({
                "visibility":"visible",
                "opacity":"0",
                "width":"fit-content",
                'letter-spacing':20
            })

            $('.sec_two .overlay').css({
                'opacity':0
            })

            $('.sec_two span video').css({
                transform:'scale(1)',
                transition:'transform .5s'
            })
        }

        //Wip animate out Trigger
        /*if(progress > main_sec_two.offset().top + 230){
            var fillScreen = (107 - Math.max(0,Math.min(progress*35,12)))

            var clamp =  Math.max(0,Math.min((progress-.2)*250,500))
           
            sec_two.css({
                width:fillScreen + '%',
                height:fillScreen + 'vh',
                'border-radius':clamp + 'px',
                transition:'width .4s ease-out',
                
                
            })
        }*/

          //Studio Trigger
          if(progress > main_sec_studio.offset().top){
            var figure = 120;
             var squareCorners =(((progress) - (main_sec_studio.offset().top)))
            var clamp = (figure - Math.max(0,Math.min(squareCorners,figure)))
            var fillScreen = Math.max(95,Math.min((squareCorners+140)/2,100))
          
           
            sec_studio.css({
                width:100 + '%',
                height:100 + 'vh',
                //'border-radius':0 + 'px',
                //transition:' width .5s ease-out,border-radius .3s',
                
               
                
            })

            studio_button_trans1.css({
                "transform":" translateY(-100px)"
            })

            studio_button_trans2.css({
                "transform":" translateY(0px)"
            })

            studio_label_h1.css({
                'color':"black"
            })

            label_button.add(studio_label_button).add(label_activation_button).css({
                'color':'black'
            })

            /*$('.sec_studio span video').css({
                transform:'scale(1.2)',
                transition:'transform .5s'
            })*/
            nav.css({
                color:"black",
                'text-shadow':'0px 0px 0px'
            })

            studio_label.css({
                "padding":"40px 30px",
                opacity:1
            })

            studio_label_h1.css({
                "visibility":"visible",
                "opacity":"1",
                "width":"fit-content",
                'letter-spacing':1
            })

            $('.sec_studio .overlay').css({
                'opacity':1
            })

        }else{
            sec_studio.css({
                width:100  + '%',
                height:fillScreen + 'vh',
                //transition:' width .5s ease-out,border-radius .3s',
                //'border-radius':100+'px',
               
                
            })

            studio_label.css({
                "padding":"40px 40px",
                opacity:0
            })

            studio_label_h1.css({
                "visibility":"visible",
                "opacity":"0",
                "width":"fit-content",
                'letter-spacing':20
            })

            $('.sec_studio .overlay').css({
                'opacity':0
            })

            $('.sec_studio span video').css({
                transform:'scale(1)',
                transition:'transform .5s'
            })
            nav.css({
                color:'white',
                'text-shadow':'0px 0px 2px black'
            })
        }

         //Studio Trigger
         if(progress > main_sec_studio.offset().top + 230){
            var fillScreen = (107 - Math.max(0,Math.min(progress*35,12)))

            var clamp =  Math.max(0,Math.min((progress-.2)*250,500))
           
           /* sec_studio.css({
                width:fillScreen + '%',
                height:fillScreen + 'vh',
                'border-radius':clamp + 'px',
                transition:'width .2s ease-out',
                
            })*/
        }

        if(progress > main_sec_three.offset().top){

           var figure = 120;
             var squareCorners =(((progress) - (main_sec_three.offset().top)))
            var clamp = (figure - Math.max(0,Math.min(squareCorners,figure)))
            var fillScreen = Math.max(95,Math.min((squareCorners+140)/2,100))
            //console.log(squareCorners)

            sec_three.css({
                width:100 + '%',
                //height:fillScreen + 'vh',
                //'border-radius':0 + 'px',
                //transition:' width .5s ease-out,border-radius .3s',
                
            })
            
            nav.css({
                color:'#fff',
                'text-shadow':'0px 0px 2px black'
            })

            label_activation.css({
                "padding":"30px 50px",
                opacity:1,
                //"transition": "1.5s ease-out"

            })

            label_activation_h1.css({
                "visibility":"visible",
                "opacity":"1",
                "width":"fit-content",
                'letter-spacing':1
            })

            $('.sec_three .overlay').css({
                'opacity':1
            })

            activation_button_trans1.css({
                "transform":" translateY(-100px)"
            })

            activation_button_trans2.css({
                "transform":" translateY(0px)"
            })

            label_activation_h1.css({
                'color':"black"
            })

            /*$('.sec_three span video').css({
                transform:'scale(1.2)',
                transition:'transform .5s'
            })*/

            
        }else{
            sec_three.css({
                width:100+ '%',
                //height:fillScreen + 'vh',
                //'border-radius':clamp + 'px',
                //transition:' width .5s ease-out,border-radius .3s',
                
            })

            $('.sec_three .overlay').css({
                'opacity':0
            })

            $('.sec_three span video').css({
                transform:'scale(1)',
                transition:'transform .5s'
            })

            label_activation.css({
                "padding":"30px 50px",
                opacity:0
                //"transition": "1.5s ease-out"

            })

            label_activation_h1.css({
                "visibility":"visible",
                "opacity":"0",
                "width":"fit-content",
                'letter-spacing':30
            })

        }

       

      

       
    }
});






var close_gallery = $('.close_gallery');
var gallery_main_container = $('.gallery_main_container')
var close_button = $('.close_button')
close_gallery.click(closeGallery)
const gallery= $('.gallery')
const time_elapse = 2000;
const view_moongirls = $('.view_moongirls');

//EventListeners
gallery.on('mousemove',showCloseButton)



const jabari_arrey = [
    "https://drive.google.com/uc?export=view&id=140iWt8INdqns2qM0a-Qn9YQO_J8_VXnL",
    "https://drive.google.com/uc?export=view&id=1Zvr5lQIn1i2xvAEDWHqZUgZvh4Em7nGL",
    "https://drive.google.com/uc?export=view&id=1dw23FKhFrdsl5nBhwxp-YVN37p38dqq1",
    "https://drive.google.com/uc?export=view&id=13akHmwoW2bVuawykeoOj7p9wZTH8CUCc",
    "https://drive.google.com/uc?export=view&id=1eBBUbX_o9qbvKkarESKoOWZe3sKjCXPk",
    "https://drive.google.com/uc?export=view&id=1YomF56Gx0NH1SpdOfqAIcFobKL-3Dh0F",
    "https://drive.google.com/uc?export=view&id=19oW--CD4VyLjnC6zQCM0g_7OTrk22mqI",
    "https://drive.google.com/uc?export=view&id=1EU5wbs0lb1GXpFw6UEdnD1-3DWkMVMl0",
    "https://drive.google.com/uc?export=view&id=1NrP5xsse5OhhneVL_L7AL0xb59Z1mvK6",    
    "https://drive.google.com/uc?export=view&id=1_401UjVIy52Poe9apaimVfKfr0ziceZD",    
    "https://drive.google.com/uc?export=view&id=1gZHLrAFBPz_Z7vQusg-H01W_LO9__FAW",  
    "https://drive.google.com/uc?export=view&id=1CQ-A3I2CElp6oQZ2RG8qZBZxPbyUMixt",
]




const arrow_left = $('.arrow_left');
const arrow_right = $('.arrow_right')

var gallery_index = 0;
var move=0
var value = 20;

/*
moongirls_gallery.forEach((images,index)=>{
    const gallery_template = `
    <div id="moon${index}">
     <img src="${images}" alt="animaxfyb gallery"> 
    </div>
    `
    gallery.append(gallery_template)
    
  
})*/








function closeGallery(){
  gallery_main_container.hide()

}

function closeButtonAction(){
    close_gallery.css({
        opacity:1,
        top:'5%'
    })


}

function showCloseButton (){
   closeButtonAction();
}

const viewMoonGirls = ()=>{
    gallery_main_container.show()
}


//click Functions
view_moongirls.click(viewMoonGirls)


//Gsap Animations
var tl = gsap.timeline();
var transition1 = $('.transition1');
var transition2 = $('.transition2');
var transition_holder = $('.transition_holder')
//var navigations = $('a')
gsap.set(transition1,
    {x:'-100%',
    alpha:1
})

gsap.set($('main'),{
    alpha:0
})

gsap.to(transition1,{
    x:0,
    duration: 1, 
    ease: "power4.inOut",
    delay:.2
    
})
gsap.to(transition1,{
    x:"100%",
    delay:1,
    duration: 1, 
    ease: "power4.inOut"
})

//transition2

gsap.set(transition2,
    {x:'-100%',
    alpha:1
})
gsap.to(transition2,{
    x:0,
    duration: 1, 
    ease: "power4.inOut"
    
})
gsap.to(transition2,{
    x:"100%",
    delay:1.1,
    duration: 1, 
    ease: "power4.inOut",
})

gsap.to($('main'),{
    alpha:1,
    delay:.5
})

//End of Gsap Animation


 $(document).ready(function(){
  //Mobile View Functions
  const mobile_view = window.matchMedia('(max-width:900px)')

  if(mobile_view.matches){

    $(window).scroll(function(){
      var scrollTop = $(window).scrollTop();
      const main_sec_studio = $('.main_sec_studio');
      const main_sec_two = $('.main_sec_two')
      const main_sec_three = $('.main_sec_three')
      const studio_label = $('.studio_label')
      const sec_studio_overlay = $('.sec_studio .overlay')
      const sec_two_overlay = $('.main_sec_two .overlay')
      const sec_three_overlay = $('.main_sec_three .overlay')
      const label = $('.label')
      const label_activation = $('.label_activation')

      studio_label.css("opacity","0")

      if(scrollTop > main_sec_studio.offset().top - 200){
        studio_label.css({
          opacity:1,
          'margin-top':'0px'
        })

        sec_studio_overlay.css({
          opacity:1
        })
      }else{
        studio_label.css({
          opacity:0,
          'margin-top':'100px'
        })
      }

      if(scrollTop> main_sec_two.offset().top - 200){
        label.css({
          opacity:1,
          'margin-top':"0px"
        })

        sec_two_overlay.css({
          opacity:1
        })
      }else{
        label.css({
          opacity:0,
          'margin-top':"100px"
        })

      }

      if(scrollTop> main_sec_three.offset().top - 200){
        label_activation.css({
          opacity:1,
          'margin-top':"0px"
        })

        sec_three_overlay.css({
          opacity:1
        })
      }else{
        label_activation.css({
          opacity:0,
          'margin-top':"100px"
        })

      }
    })
  }

  //Mobile View Functions Ends Here 
 })

 function getCurrentURL () {
    return window.location.href
  }
  
  // Example
  const url = getCurrentURL()

  console.log(url)

//alert('hello')

 //nav indicator
 

 var featured_banner = $('.featured_banner')

 featured_banner.click(function(){
    // $(this).css({
    //     display:"none"
    // })
 })

